<script lang="ts" setup>
import { onMounted } from 'vue';

import type { VReCaptchaEmits, VReCaptchaExpose, VReCaptchaProperties } from './types';
import { useVReCaptcha } from './composables';

const props = withDefaults(defineProps<VReCaptchaProperties>(), {
  siteKey: '',
  language: '',
});

const emits = defineEmits<VReCaptchaEmits>();

const {
  root,
  execute,
  reset,
  onComponentCreated,
  onComponentMounted,
} = useVReCaptcha(props, emits);

onMounted(() => {
  onComponentCreated();
  void onComponentMounted();
});

defineExpose<VReCaptchaExpose>({
  reset,
  execute,
});
</script>

<template>
  <div v-auto-id="'VReCaptcha'"
    ref="root"
    :class="$style['the-re-captcha']"
  />
</template>

<style lang="scss" module>
.the-re-captcha {
  transform: scale(0.9);
}
</style>
