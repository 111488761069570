import { Timer } from '@leon-hub/utils';

import { RecaptchaV3Container } from '../RecaptchaV3Container';

export async function waitForCaptchaV3IsReady(): Promise<void> {
  if (!RecaptchaV3Container.isLoading) {
    return Promise.resolve();
  }
  let timeout = 0;
  return new Promise((resolve) => {
    const checkLoading = () => {
      if (!RecaptchaV3Container.isLoading) {
        Timer.clearTimeout(timeout);
        resolve();
      } else {
        timeout = Timer.setTimeout(checkLoading, 300);
      }
    };
    checkLoading();
  });
}
