export const liveStreamIframeWidgetName = 'liveStream' as const;

export const sportradarLiveMatchTrackerIframeWidgetName = 'sportradarLiveMatchTracker' as const;
export const sportradarLiveMatchTrackerLegacyIframeWidgetName = 'liveWidget' as const;
export const sportradarStatisticsIframeWidgetName = 'sportradarStatistics' as const;
export const sportradarLiveScoreIframeWidgetName = 'sportradarLiveScore' as const;

export const oddinLiveMatchTrackerIframeWidgetName = 'oddinLiveMatchTracker' as const;
export const oddinPrematchStatisticsIframeWidgetName = 'oddinPrematchStatistics' as const;

export const lSportsIframeWidgetName = 'lsports' as const;
export const lSportsLegacyIframeWidgetName = 'lsportsWidget' as const;

export const betgeniusIframeWidgetName = 'betgenius' as const;
export const betgeniusLegacyIframeWidgetName = 'geniusSportsPitchWidget' as const;

export const virtualSportIframeWidgetName = 'virtualSport' as const;

export const betGamesIframeWidgetName = 'betgames' as const;

export const twitterTimelineIframeWidgetName = 'twitterTimeline' as const;

export const captchaIframeWidgetName = 'captcha' as const;

export const egsGameIframeWidgetName = 'egsGame' as const;
export const prerenderEgsGameIframeWidgetName = 'play-game' as const;
