import { PostMessageEvent } from '@leon-hub/postmessage-bus';

import type {
  OddinReadyStateChangedEvent,
  OddinResizeEvent,
} from '../types';

export class PrematchOddinPostMessageEvent extends PostMessageEvent {
  static readonly loaded = new PostMessageEvent('live-oddin-widget-loaded');

  static readonly readyStateChanged = new PostMessageEvent<OddinReadyStateChangedEvent>('prematch-oddin-widget-ready-state-changed');

  static readonly resize = new PostMessageEvent<OddinResizeEvent>('prematch-oddin-widget-resize');
}
