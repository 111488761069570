import type Logger from '@leon-hub/logging';
import {
  assert,
  isFunction,
  isString,
} from '@leon-hub/guards';
import { logger as loggerInstance } from '@leon-hub/logging';
import { Deferred } from '@leon-hub/utils';

import type { ReCaptchaV2Instance, VReCaptchaOptions } from '../components/VReCaptcha/types';
import type { ReCaptchaResponse } from '../store/types';
import type ReCaptchaService from './RecaptchaService';
import RECAPTCHA_CHALLENGE_SELECTOR from '../components/VReCaptcha/constants/reCaptchaChallengeSelector';
import CaptchaServiceError from './errors/CaptchaServiceError';
import { RecaptchaV2Container } from './RecaptchaV2Container';
import { isReCaptchaV2Instance } from './utils';
import { waitForCaptchaV3IsReady } from './utils/waitForCaptchaV3IsReady';

function assertExecution(instance: ReCaptchaV2Instance, method: keyof ReCaptchaV2Instance) {
  if (!isFunction(instance[method])) {
    throw new Error(`ReCAPTCHA instance doesn't have ${method} method`);
  }
}

interface ReCaptchaServiceOptions {
  scriptId?: string;
}

const getChallengeParentElement: () => HTMLElement | null = () => document
  ?.querySelector<HTMLElement>(RECAPTCHA_CHALLENGE_SELECTOR)
  ?.parentElement
  ?.parentElement ?? null;

/**
 * ReCaptcha Manifestation
 * https://github.com/google/recaptcha/issues/231
 * https://github.com/google/recaptcha/issues/286
 */
export default class DefaultReCaptchaService implements ReCaptchaService {
  private deferredLoad: Deferred<null>;

  private deferredExecute: Deferred<ReCaptchaResponse>;

  // eslint-disable-next-line sonarjs/public-static-readonly
  static $instance: DefaultReCaptchaService | null = null;

  static getInstance(): DefaultReCaptchaService {
    if (DefaultReCaptchaService.$instance) {
      return DefaultReCaptchaService.$instance;
    }

    DefaultReCaptchaService.$instance = new DefaultReCaptchaService();

    return DefaultReCaptchaService.$instance;
  }

  constructor(private readonly options?: ReCaptchaServiceOptions, private readonly logger: Logger = loggerInstance) {
    this.deferredLoad = new Deferred();
    this.deferredExecute = new Deferred();
  }

  notifyApiLoaded(): void {
    this.deferredLoad.resolve(null);
  }

  notifyExecuting(response: ReCaptchaResponse): void {
    this.deferredExecute.resolve(response);
  }

  deferredExecutingReset(): void {
    this.deferredExecute = new Deferred();
  }

  async loadRecaptchaScript(apiUrl: string): Promise<null> {
    /**
     * V2 and V3 shares same global variable "grecaptcha"
     * V3 must be loaded at start if enabled
     * func below to prevent collision
     */
    await waitForCaptchaV3IsReady();
    void RecaptchaV2Container.init(apiUrl).then(() => this.notifyApiLoaded());

    return this.deferredLoad.promise;
  }

  async tryLoadRecaptchaScript(apiUrl: string, retryCount = 3, attempt = 1): Promise<null> {
    return await this.loadRecaptchaScript(apiUrl)
      .catch((error) => {
        this.deferredLoad = new Deferred();
        if (attempt >= retryCount) {
          throw new CaptchaServiceError({
            log: `Unable to load captcha script at ${apiUrl} after ${retryCount} tries`,
            originalError: error,
          });
        }

        return this.tryLoadRecaptchaScript(apiUrl, retryCount, attempt + 1);
      });
  }

  wait(): Promise<null> {
    return this.deferredLoad.promise;
  }

  // eslint-disable-next-line ts/no-unsafe-function-type
  render(element: HTMLElement, options: VReCaptchaOptions, callback: Function): void {
    this.wait().then(() => {
      if (this.grecaptcha && isReCaptchaV2Instance(this.grecaptcha)) {
        assertExecution(this.grecaptcha, 'render');
        callback(this.grecaptcha?.render(element, options));
      }
    }).catch(this.executionErrorHandler.bind(this));
  }

  reset(): void {
    assert(this.deferredLoad.resolved, '[ReCaptchaService:reset] ReCAPTCHA has not been loaded.');
    this.wait().then(() => {
      if (this.grecaptcha && isReCaptchaV2Instance(this.grecaptcha)) {
        assertExecution(this.grecaptcha, 'reset');
        this.grecaptcha?.reset();
      }
    }).catch(this.executionErrorHandler.bind(this));
  }

  private get grecaptcha(): ReCaptchaV2Instance | null {
    return RecaptchaV2Container.grecaptchaInstance;
  }

  async execute(): Promise<string | null | undefined> {
    assert(this.deferredLoad.resolved, '[ReCaptchaService:execute] ReCAPTCHA has not been loaded.');
    return this.wait().then(() => {
      if (this.grecaptcha && isReCaptchaV2Instance(this.grecaptcha)) {
        assertExecution(this.grecaptcha, 'execute');
        return this.grecaptcha?.execute();
      }
      return undefined;
    }).catch(this.executionErrorHandler.bind(this));
  }

  checkRecaptchaLoad(): boolean {
    if (isReCaptchaV2Instance(this.grecaptcha)) {
      this.notifyApiLoaded();
      return true;
    }
    this.logger.warn('ReCAPTCHA has not been loaded');
    return false;
  }

  executionErrorHandler(error: Error): null {
    this.logger.error(error, 'ReCaptcha execution error occurred');
    return null;
  }

  async getResponse(): Promise<string | null | undefined> {
    assert(this.deferredLoad.resolved, '[ReCaptchaService:getResponse] ReCAPTCHA has not been loaded.');
    return this.wait().then(() => {
      if (this.grecaptcha && isReCaptchaV2Instance(this.grecaptcha)) {
        assertExecution(this.grecaptcha, 'getResponse');
        return this.grecaptcha?.getResponse();
      }
      return null;
    }).catch(this.executionErrorHandler.bind(this));
  }

  async invalidate(): Promise<ReCaptchaResponse> {
    const captchaResponse = await this.getResponse();

    if (isString(captchaResponse) && captchaResponse.length > 0) {
      return Promise.resolve({ captchaResponse });
    }

    await this.execute();
    const result = await this.deferredExecute.promise;
    this.deferredExecutingReset();
    return result;
  }

  isChallengeVisible(): boolean {
    const element: HTMLElement | null = getChallengeParentElement();

    if (element) {
      return window.getComputedStyle(element).visibility !== 'hidden';
    }

    return false;
  }
}
