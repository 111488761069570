import type { RecaptchaContainer } from '../../types';

interface Payload<T> {
  getRecaptchaScript(scriptSrc: string): Promise<T>;
}

export function createRecaptchaContainer<T>({ getRecaptchaScript }: Payload<T>): RecaptchaContainer<T> {
  let grecaptchaInstance: T | null = null;
  let isLoading = false;

  const init = async (scriptSrc: string): Promise<void> => {
    if (!grecaptchaInstance) {
      isLoading = true;
      grecaptchaInstance = await getRecaptchaScript(scriptSrc);
      isLoading = false;
    }
  };

  return Object.freeze({
    get grecaptchaInstance() {
      return grecaptchaInstance;
    },
    get isLoading() {
      return isLoading;
    },
    init,
  });
}
