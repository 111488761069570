<script lang="ts" setup>
import {
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';

import { VLoader } from '@components/loader';

import type { VLoaderDelayedProps } from './types';
import { useVLoaderDelayed } from './composables';

const props = withDefaults(defineProps<VLoaderDelayedProps>(), {});
const {
  loaderIsVisible,
  onDisappear,
  onAppear,
} = useVLoaderDelayed(props);

onBeforeMount(onAppear);
onBeforeUnmount(onDisappear);
</script>

<template>
  <VLoader v-auto-id="'VLoaderDelayed'"
    v-if="loaderIsVisible"
    :title="title"
    :hint="hint"
    :size="size"
    :color="color"
    :margin="margin"
  />
</template>
