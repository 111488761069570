import type { ReCaptchaV2Instance } from '../components/VReCaptcha/types';
import type { RecaptchaContainer } from '../types';
import { createRecaptchaContainer, getRecaptchaV2Script } from './utils';

function createRecaptchaV2Container(): RecaptchaContainer<ReCaptchaV2Instance> {
  return createRecaptchaContainer<ReCaptchaV2Instance>({
    getRecaptchaScript: getRecaptchaV2Script,
  });
}

export const RecaptchaV2Container: RecaptchaContainer<ReCaptchaV2Instance> = createRecaptchaV2Container();
