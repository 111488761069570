import type { ReCaptchaV3Instance } from '../components/VReCaptcha/types';
import type { RecaptchaContainer } from '../types';
import { createRecaptchaContainer, getRecaptchaV3Script } from './utils';

function createRecaptchaV3Container(): RecaptchaContainer<ReCaptchaV3Instance> {
  if (!process.env.VUE_APP_FEATURE_RECAPTCHA_V3_ENABLED || process.env.VUE_APP_PRERENDER) {
    return Object.freeze({
      grecaptchaInstance: null,
      isLoading: false,
      init() {
        return Promise.resolve();
      },
    });
  }
  return createRecaptchaContainer<ReCaptchaV3Instance>({
    getRecaptchaScript: getRecaptchaV3Script,
  });
}

export const RecaptchaV3Container: RecaptchaContainer<ReCaptchaV3Instance> = createRecaptchaV3Container();
