import { Timer } from '@leon-hub/utils';

interface SportRadarVideoPlayer {
  api?: {
    isMobile: boolean;
    play?(error: unknown, A: unknown, t: unknown, o: unknown): Promise<boolean>;
    mobilePlay?(playerType?: string): void;
    mute?(): boolean;
    resume?(): boolean;
    isReady?(): boolean;
    isPlaying?(): boolean;
  };
}

interface SportRadarVideoPlayerWidget {
  loading?: boolean;
  videoplayer?: SportRadarVideoPlayer;
}

export default class VirtualSportMobileAutoplay {
  private timeout = 0;

  constructor(
    private checkInterval = 500,
    private maxChecks = 40,
  ) {}

  private get videoPlayerWidget(): SportRadarVideoPlayerWidget | undefined {
    const widgetSelector = `vswidgets.${window.SRLive?.currentSport || 'core'}.videoplayer`;
    const widgets = window.SRLive?.w
      .getAll<SportRadarVideoPlayerWidget>(widgetSelector) ?? [];

    return widgets.find((widget) => !!widget.videoplayer);
  }

  private get isReady(): boolean {
    return this.videoPlayerWidget?.videoplayer?.api?.isReady?.() ?? false;
  }

  start(currentCheckNumber = 0): void {
    if (currentCheckNumber > this.maxChecks) {
      return;
    }

    if (this.isReady) {
      this.play();
      return;
    }

    this.timeout = Timer.setTimeout(() => {
      this.timeout = 0;
      this.start(currentCheckNumber + 1);
    }, this.checkInterval);
  }

  stop(): void {
    if (this.timeout) {
      Timer.clearTimeout(this.timeout);
    }
  }

  play(): void {
    const player = this.videoPlayerWidget?.videoplayer;

    if (!player) {
      return;
    }

    if (player.api?.isPlaying?.()) {
      return;
    }

    // something else is loading after video player is ready
    Timer.setTimeout(() => {
      player.api?.mute?.();
      player.api?.mobilePlay?.();
    }, 500);
  }
}
