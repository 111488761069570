import { ScriptElement } from '@leon-hub/utils';

import type { ReCaptchaV2Instance } from '../../components/VReCaptcha/types';
import { reCaptchaApiLoadedCallbackName } from '../../components/VReCaptcha/constants';
import { isReCaptchaV2Instance } from './isReCaptchaV2Instance';

export async function getRecaptchaV2Script(scriptSrc: string): Promise<ReCaptchaV2Instance> {
  return new Promise((resolve, reject) => {
    const readyCallback = () => {
      const grecaptchaInstance = window.grecaptcha;
      if (isReCaptchaV2Instance(grecaptchaInstance)) {
        window.grecaptcha = undefined;
        window[reCaptchaApiLoadedCallbackName] = undefined;
        resolve(grecaptchaInstance);
      } else {
        reject(new Error('Unexpected ReCaptchaV2Instance value'));
      }
    };

    if (process.env.VUE_APP_RENDERING_CSR) {
      window[reCaptchaApiLoadedCallbackName] = readyCallback;
    }

    const recaptchaV2Script = new ScriptElement({
      onerror: () => {
        reject(new Error('Unable to load Recaptcha V2 Script'));
      },
      async: true,
      defer: true,
      appendTarget: 'head',
      src: scriptSrc,
      name: 'recaptchaV2',
    });

    void recaptchaV2Script.addScript();
  });
}
