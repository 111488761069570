import { ScriptElement } from '@leon-hub/utils';

import type { ReCaptchaV3Instance } from '../../components/VReCaptcha/types';
import { isReCaptchaV3Instance } from './isReCaptchaV3Instance';

export async function getRecaptchaV3Script(scriptSrc: string): Promise<ReCaptchaV3Instance> {
  const loadErrorText = 'Unable to load Recaptcha V3 Script';
  return new Promise((resolve, reject) => {
    const onLoad = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          if (isReCaptchaV3Instance(window.grecaptcha)) {
            const grecaptchaInstance = window.grecaptcha;
            window.grecaptcha = undefined;
            resolve(grecaptchaInstance);
          } else {
            reject(new Error('Unexpected grecaptcha value'));
          }
        });
      } else {
        reject(new Error(loadErrorText));
      }
    };

    const recaptchaV3Script = new ScriptElement({
      onload: () => onLoad(),
      onerror: () => {
        reject(new Error(loadErrorText));
      },
      async: true,
      defer: true,
      appendTarget: 'head',
      src: scriptSrc,
      name: 'recaptchaV3',
    });

    void recaptchaV3Script.addScript();
  });
}
