import { Timer } from '@leon-hub/utils';

import type { ReCaptchaV3Instance } from '../../components/VReCaptcha/types';

interface Payload {
  captchaInstance: ReCaptchaV3Instance;
  siteKey: string;
  timeout: number;
  action?: string;
}

export function getRecaptchaV3Token({ captchaInstance, timeout, siteKey, action }: Payload): Promise<string> {
  let timer: number = 0;
  return new Promise((resolve, reject) => {
    if (!captchaInstance) {
      reject(new Error('there is no CaptchaV3 is instance'));
    }
    timer = Timer.setTimeout(() => {
      reject(new Error(`Failed to get ReCaptchaV3 token in ${timeout}ms`));
    }, timeout);
    captchaInstance.ready(() => {
      void captchaInstance.execute(siteKey, { action }).then((token) => {
        Timer.clearTimeout(timer);
        resolve(token);
      });
    });
  });
}
