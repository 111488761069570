import { ReCaptchaSize, ReCaptchaType } from '@modules/captcha-utilities';

import type { VReCaptchaProperties, VReCaptchaWidgetSize } from 'web/src/modules/captcha/components/VReCaptcha/types';

export default function getVRecaptchaWidgetSize(props: VReCaptchaProperties): VReCaptchaWidgetSize {
  if (props.type === ReCaptchaType.CHALLENGE_INVISIBLE) {
    return ReCaptchaSize.INVISIBLE;
  }
  return ReCaptchaSize.NORMAL;
}
