export const encodedIFrameUrlQueryParameter = 'url' as const;

export const loadedEventName = 'LOADED' as const;

export const dataAvailabilityEventName = 'DATA' as const;

export const closeEventName = 'CLOSE' as const;

export const resizeEventName = 'RESIZE' as const;

export const scrollTopEventName = 'SCROLL_TOP' as const;
