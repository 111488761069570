var ReCaptchaType = /* @__PURE__ */ ((ReCaptchaType2) => {
  ReCaptchaType2["CHALLENGE_CHECKBOX"] = "CHALLENGE_CHECKBOX";
  ReCaptchaType2["CHALLENGE_INVISIBLE"] = "CHALLENGE_INVISIBLE";
  ReCaptchaType2["SCORE"] = "SCORE";
  return ReCaptchaType2;
})(ReCaptchaType || {});
var CaptchaRequesterStrategy = /* @__PURE__ */ ((CaptchaRequesterStrategy2) => {
  CaptchaRequesterStrategy2["RESTORE_PASSWORD"] = "RESTORE_PASSWORD";
  CaptchaRequesterStrategy2["REGISTRATION"] = "REGISTRATION";
  CaptchaRequesterStrategy2["LOGIN"] = "LOGIN";
  CaptchaRequesterStrategy2["DEFAULT"] = "DEFAULT";
  return CaptchaRequesterStrategy2;
})(CaptchaRequesterStrategy || {});
var ReCaptchaTheme = /* @__PURE__ */ ((ReCaptchaTheme2) => {
  ReCaptchaTheme2["DARK"] = "dark";
  ReCaptchaTheme2["LIGHT"] = "light";
  return ReCaptchaTheme2;
})(ReCaptchaTheme || {});
var ReCaptchaSize = /* @__PURE__ */ ((ReCaptchaSize2) => {
  ReCaptchaSize2["COMPACT"] = "compact";
  ReCaptchaSize2["NORMAL"] = "normal";
  ReCaptchaSize2["INVISIBLE"] = "invisible";
  return ReCaptchaSize2;
})(ReCaptchaSize || {});
var ChallengeState = /* @__PURE__ */ ((ChallengeState2) => {
  ChallengeState2["CHALLENGE_IS_CLOSED"] = "challenge-is-closed";
  ChallengeState2["CHALLENGE_IS_OPENED"] = "challenge-is-opened";
  return ChallengeState2;
})(ChallengeState || {});
export {
  CaptchaRequesterStrategy,
  ChallengeState,
  ReCaptchaSize,
  ReCaptchaTheme,
  ReCaptchaType
};
